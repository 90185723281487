import propTypes from 'prop-types';
import { BarChart } from '@components';
import styles from './ParticipationChart.module.scss';

const ParticipationChart = ({ data }) => {
  // Extract arrays for months, optedIn, and optedOut
  const barLabels = data.map(entry => entry.key);
  const optedInStats = data.map(entry => parseInt(entry.optedIn, 10));
  const optedOutStats = data.map(entry => parseInt(entry.optedOut, 10));

  const chartData = {
    labels: barLabels,
    backgroundColors: [['#009de1'], ['#5C6BC0']],
    barTitle: ['Opted-In', 'Opted-Out'],
    data: [optedInStats, optedOutStats],
  };

  return (
    <div className={styles.participationChart}>
      <BarChart
        title="Campaign Participation Graph"
        chartData={chartData}
        legendConfig={{
          display: true,
          position: 'top',
          padding: 5,
          labels: {
            usePointStyle: false, // Use circles for legend markers
            pointStyle: 'circle', // Rounded point style
            boxWidth: 10, // Control the width of the circles
            boxHeight: 10, // Control the height of the circles (if needed)
            padding: 10, // Padding between the items
          },
        }}
      />
    </div>
  );
};

ParticipationChart.propTypes = {
  data: propTypes.any,
};

export default ParticipationChart;
